<template>
    <client-page>
        <sub-visual tit="Contact Us" bg="/images/sub/visual/sv--inquiry.jpg"></sub-visual>

        <section class="section secondary color-white">
            <v-container>
                <div class="d-flex align-end justify-space-between mb-20 mb-lg-40">
                    <h5 class="tit-txt font-weight-bold letter-spacing-6 line-height-1">
                        INQUIRY
                    </h5>
                    <v-spacer />
                    <v-col cols="auto" class="pa-0" v-if="catalog">
                        <v-btn small plain tile color="white" :href="`/res/enCatalog/${catalog.filename}`" target="_blank" class="transparent text-btn d-flex align-center px-4" style="border-radius: 0!important;">
                            General Catalog <i class="icon icon-download"></i>
                        </v-btn>
                        <!-- <a :href="`/res/enCatalog/${catalog.filename}`" target="_blank" class="text-btn d-flex align-center">General Catalog <i class="icon icon-download"></i></a> -->
                    </v-col>
                    <v-col cols="auto" class="ml-12 pa-0" v-if="catalog2">
                        <v-btn small plain tile color="white" :href="`/res/enCatalog2/${catalog2.filename}`" target="_blank" class="transparent text-btn d-flex align-center px-4" style="border-radius: 0!important;">
                            Artbanner Stand Catalog <i class="icon icon-download"></i>
                        </v-btn>
                        <!-- <a :href="`/res/enCatalog/${catalog2.filename}`" target="_blank" class="text-btn d-flex align-center">Artbanner Stand Catalog <i class="icon icon-download"></i></a> -->
                    </v-col>
                    <!-- <button class="text-btn d-flex align-center" @click="download(`/res/catalog/${catalog.filename}`, '카탈로그')">카탈로그 <i class="icon icon-download"></i></button> -->
                    <!-- <button class="text-btn d-flex align-center" @click="view = true">Catalogue</button>
                    <v-dialog v-model="view" width="1024" height="1">
                        <embed :src="`/res/enCatalog/${catalog.filename}`" type="application/pdf"/>
                    </v-dialog> -->
                </div>
                <div>
                    <v-row class="row--large">
                        <v-col cols="12" lg="6">
                            <v-text-field  v-model="input.name" ref="name" hide-details outlined label="Your Name"></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <v-text-field  v-model="input.email" ref="email" hide-details outlined label="Business Email"></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <v-text-field v-model="input.companyName" ref="companyName" hide-details outlined label="Company Name"></v-text-field>
                        </v-col>                        
                        <v-col cols="12" lg="6">
                            <v-text-field  v-model="input.phone" ref="phone" hide-details outlined label="Phone Number"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea v-model="input.type" ref="type" hide-details outlined label="Comment"></v-textarea>
                        </v-col>
                    </v-row>
                    <div class="mt-50 mt-lg-100 d-flex justify-center">
                        <v-btn @click="send" large color="primary" class="v-btn--primary">
                            <span class="secondary--text">Submit</span>
                            <i class="icon icon-right-arrow ml-12 ml-lg-20"></i>
                        </v-btn>
                    </div>
                </div>
            </v-container>
        </section>

        <section class="section">
            <v-container>
                <v-row class="row--large">
                    <v-col cols="12" md="6">
                        <h5 class="tit-txt font-weight-bold letter-spacing-6 line-height-1">
                            CONTACT US DIRECTLY
                        </h5>
                        <v-divider class="border-color-secondary my-20 my-lg-40"></v-divider>
                        <ul class="page-text--sm line-height-1">
                            <li class="d-flex mb-8 mb-lg-30">
                                <p class="info-tit font-weight-bold mr-14 mr-lg-40" data-aos="fade-right">
                                    Company
                                </p>
                                <p class="color-grey-7" data-aos="fade-left">
                                    Minart Frame
                                </p>
                            </li>
                            <li class="d-flex mb-8 mb-lg-30">
                                <p class="info-tit font-weight-bold mr-14 mr-lg-40" data-aos="fade-right">
                                    Zip Code
                                </p>
                                <p class="color-grey-7" data-aos="fade-left">
                                    18284
                                </p>
                            </li>
                            <li class="d-flex mb-8 mb-lg-30">
                                <p class="info-tit font-weight-bold mr-14 mr-lg-40" data-aos="fade-right">
                                    Address
                                </p>
                                <p class="color-grey-7" data-aos="fade-left">
                                    <a target="_blank" href="https://www.google.com/maps/place/Minart/data=!3m1!4b1!4m6!3m5!1s0x357c9f7ab2b3ec59:0x40a3fb94d5273f9f!8m2!3d37.2365537!4d126.8726446!16s%2Fg%2F1tj94b_t?hl=en">
                                        103, Yangno-ro, Bibong-myeon, Hwaseong-si, Gyeonggi-do, South Korea
                                    </a>
                                </p>
                            </li>
                            <li class="d-flex mb-8 mb-lg-30">
                                <p class="info-tit font-weight-bold mr-14 mr-lg-40" data-aos="fade-right">
                                    Email
                                </p>
                                <p class="color-grey-7" data-aos="fade-left">
                                    <a target="_blank" href="mailto:minart@minart.com">minart@minart.com</a>
                                </p>
                            </li>
                            <li class="d-flex mb-8 mb-lg-30">
                                <p class="info-tit font-weight-bold mr-14 mr-lg-40" data-aos="fade-right">
                                    Website
                                </p>
                                <p class="color-grey-7" data-aos="fade-left">
                                    <router-link to="/">minart.com</router-link>
                                </p>
                            </li>
                            <li class="d-flex">
                                <p class="info-tit font-weight-bold mr-14 mr-lg-40" data-aos="fade-right">
                                    Tel / Fax
                                </p>
                                <p class="color-grey-7" data-aos="fade-left">
                                    <a target="_blank" href="tel:+82-31-465-9088">+82-31-465-9088</a> / +82-31-465-9098
                                </p>
                            </li>
                        </ul>
                    </v-col>
                    <v-col cols="12" md="6">
                        <div class="rounded mt-md-40 mt-lg-62">
                            <iframe class="w-100 d-block" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3176.470045870956!2d126.8726446!3d37.23655370000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9f7ab2b3ec59%3A0x40a3fb94d5273f9f!2sMinart!5e0!3m2!1sen!2skr!4v1675299636106!5m2!1sen!2skr" width="100%" height="366" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </section>

        <!-- <v-card-title>제품문의</v-card-title>
        <v-card>
            <v-card-subtitle>IMQUIRY</v-card-subtitle>
            <v-text-field v-model="input.companyName" label="회사명"></v-text-field >
            <v-text-field  v-model="input.name" label="이름"></v-text-field >
            <v-text-field  v-model="input.email" label="이메일"></v-text-field >
            <v-text-field  v-model="input.phone" label="전화번호"></v-text-field >
            <v-text-field  v-model="input.type" label="문의종류"></v-text-field >
        </v-card>
        <v-btn @click="send">문의하기</v-btn> -->
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/en/templates/ClientPage.vue";
import SubVisual from "@/components/client/en/sub/sub-visual.vue";

import api from "@/api";
import download from "downloadjs";

export default {
    components: {
        ClientPage,
        SubVisual,
    },
    data() {
        return {
            input: {
                companyName: null,
                name: null,
                email: null,
                phone: null,
                type: null,
                language: "en"
            },
            catalog: null,
            catalog2: null,
            // view: false,
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        validate(){
            try{
                if(!this.input.name) {
                    alert("Please enter your Name");
                    this.$refs.name.focus();
                    return false;
                }
                if(!this.input.email) {
                    alert("Please enter your Business Email");
                    this.$refs.email.focus();
                    return false;
                }
                if(!this.input.companyName) {
                    alert("Please enter your Company Name");
                    this.$refs.companyName.focus();
                    return false;
                }
                if(!this.input.phone) {
                    alert("Please enter your Phone Number");
                    this.$refs.phone.focus();
                    return false;
                }
                if(!this.input.type) {
                    alert("Please enter your Comment");
                    this.$refs.type.focus();
                    return false;
                }
                return true
            }
            catch(error){
                alert(error.message);
                return false;
            }
        },
        async send() {
            if(this.validate()) {
                await api.v1.forms.post(this.input);
                alert("Done.")
                this.$router.go(0);
            }
        },
        async init() {
            let { setting } = await api.v1.setting.get();
            this.catalog = setting.enSurveyURL;
            this.catalog2 = setting.enSurveyURL2;
        },
        download(path, rename){
            api.getResource(path).then(file => download(file, rename));
        }
    },
};
</script>

<style scoped>
.info-tit{
    min-width: 55px;
    width: 55px;
    /* height: 16px;
    overflow: hidden;
	text-align:justify;
	vertical-align:middle; */
}
/* .info-tit::after{
    content:'';
	display:inline-block;
	width:100%;
} */

@media (min-width:576px){
}
@media (min-width:768px){
    .info-tit .space{
        display: inline;
    }
}
@media (min-width:1024px){
    .info-tit{
        min-width: 70px;
        width: 70px;
    }
}
@media (min-width:1200px){
}
</style>
